import React from 'react';
import '../styles/Resume.css';

// This component is mostly static texts.

const Resume = () => {
  return (
    <div id='resume-container'>
      <h1>
        CV 💬
      </h1>
      <h3>My journey so far...!</h3>
      <hr/>
      <h2>Experience 💻</h2>
      <div>
        <h3>
          Squarespace (Nov '21 - Present)
        </h3>
        <ul>
          <li>I help make a website that makes websites! :)</li>
          <li>Primarily frontend with ReactJS, Redux, YUI</li>
          <li>Occasionally some backend work using Java/Spring</li>
        </ul>
      </div>
      <div>
        <h3>
          American Express (Jul '19 - Nov '21)
        </h3>
        <ul>
          <li>Full-stack engineer on the Digital Acquisition team.</li>
          <li>ReactJS/Redux on the frontend, Node/Express backend.</li>
          <li>Analytics implementation & tracking using Adobe Analytics</li>
        </ul>
      </div>
      <div>
        <h3>
          J.P. Morgan Chase (Jun '16 - Jul '19)
        </h3>
        <ul>
          <li>CIB Fixed Income, markets execution technology.</li>
          <li>Mostly frontend engineering using ReactJS, Redux, RXJS</li>
        </ul>
      </div>
      <hr/>
      <h2>Academia 🎓</h2>
      <div>
        <h3>
          Hunter College - Adjunct Lecturer (FA'22🍂, SP'23🍃️, SP/FA'24 [🍃/🍂]️)
        </h3>
        <ul>
          <li>CSCI 395.48 - Practical Web Development</li>
        </ul>
      </div>
      <div>
        <h3>
          Hunter College - M.A Computer Science
        </h3>
        <ul>
          <li>Paper: <a href='https://ssl.linklings.net/conferences/wsc/wsc2021_program/views/includes/files/con274s3-file1.pdf' target='_blank'> Simulating SARS-CoV-2 Transmission in The New York Subway </a> - In proceedings Winter Simulation Conference 2021</li>
          <li>Final Project: <a href='https://academicworks.cuny.edu/hc_pubs/707' target='_blank'>GitHub Issue Classifier Tool</a> - Open source <a href='https://github.com/ponder-lab/GitHub-Issue-Classifier' target='_blank'> repo</a>.</li>
          <li>Notable Courses:
            <ul>
              <li>
                CSCI 760 Computational Linguistics - <a href='https://github.com/y3pio/CSCI760_CompLing/blob/master/jupyter/notebook.ipynb' target='_blank'>Final Project</a>
              </li>
              <li>
                CSCI 795.26 Intro to Reactive Programming - <a href='https://github.com/CSCI-49380-79526-Reactive-Programming/CSCI795_ReactiveProg_Project_Twitter_Stream_Scoring' target='_blank'>Final Project</a>
              </li>
              <li>
                CSCI 773 Computer Telecommunications & Network - <a href='https://github.com/y3pio/CSCI773_Networks' target='_blank'>Final Project</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div>
        <h3>
          Hunter College - B.A Computer Science
        </h3>
        <ul>
          <li>ACM Student Chapter - Charter Member.</li>
        </ul>
      </div>
      <hr/>
      <h2>Volunteering & Awards 🙌</h2>
      <div>
        <h3>
          CodeNation - Teaching Staff (Feb '20 - Jun '22)
        </h3>
        <ul>
          <li>Volunteered as part of AMEX CodeNation volunteering work.</li>
          <li>After school program to teach web dev to high school students.</li>
        </ul>
      </div>
      <div>
        <h3>
          J.P. Morgan Chase - Force For Good (2016)
        </h3>
        <ul>
          <li>Project showcase champions</li>
          <li>Pro bono work with a small team of developers.</li>
          <li>Built a prototype of a disaster response app for non-profit: All Hands and Hearts.</li>
        </ul>
      </div>
      <div>
        <h3>
          Myanmar Baptist Church New York (Ongoing)
        </h3>
        <ul>
          <li>Served as principal web developer for <a href='https://mbcnewyork.org' target='_blank'>https://mbcnewyork.org</a></li>
          <li>Ongoing work, developed 3 iterations of the website over the years.</li>
          <li>Integrated with YouTube, Tithe.ly, Formspark APIs to add additional useful functionalities to the previously static site.</li>
          <li>Migrated web host to save monthly hosting costs by 400%</li>
        </ul>
      </div>
    </div>
  );
};

export default Resume;